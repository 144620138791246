const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600],
};

const colors = {
  colorRed: '#ea3323',
};

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',

  primaryColor: colors.colorRed,
  fontColor: '#2b2b2b',
  bgColor: '#fff',

  ...colors,
  ...overloading,
};

export default variables;
