import React from 'react'
import { useRouter } from '../../components'
import { SiteLink } from '../../components'

export function BottomDock(props) {
  const links = [
    {
      url: '/club',
      title: 'CLUB',
      icon: 'club-white.png',
      iconHover: 'club.png',
    },
    {
      url: '/life-saving',
      title: 'LIFE SAVING',
      icon: 'lifesaving-white.png',
      iconHover: 'lifesaving.png',
    },
    {
      url: '/nippers',
      title: 'NIPPERS',
      icon: 'nippers-white.png',
      iconHover: 'nippers.png',
    },
    {
      url: '/surf-sports',
      title: 'SURF SPORTS',
      icon: 'surf-sports-white.png',
      iconHover: 'surf-sports-red.png',
    },
    {
      url: '/eat-drink',
      title: 'EAT & DRINK',
      icon: 'eatanddrink-white.png',
      iconHover: 'eatanddrink.png',
    },
  ]
  const { pathname } = useRouter()
  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase()
  }
  const linkElts = links.map((link, index) => (
    <li
      key={index}
      className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}
    >
      <SiteLink
        to={link.url}
        className={`bottomMenu__link bottomMenu__link--${index}`}
      >
        <div className="icon">
          <img
            className="normal"
            src={`/assets/navicons/white-icons/${link.icon}`}
            alt={link.title}
          />
          <img
            className="selected"
            src={`/assets/navicons/red-icons/${link.iconHover}`}
            alt={link.title}
          />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  ))

  return (
    <>
      <nav className="bottomMenu">
        <hr />
        <ul className="bottomMenu__links">{linkElts}</ul>
      </nav>
    </>
  )
}
