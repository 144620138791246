/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import Slider from 'react-slick';
import utils from '../../../utils';
import cmsUtils from '../../../cms/utils/cmsUtils';
import { SiteLink } from '../../../components';

export function SliderAwardPanel(props) {
  const item = props.item;
  //const defaultDesH = 220;
  const subItems = item.items || [];
  //console.log(subItems.length);
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    'cms_item',
    'sliderAwardPanel',
    item.cssClass || item.anchorName || ''
  );

  const sliderAwardItems = subItems.map((subItem, index) => {
    const imageUrl = cmsUtils.payload(subItem, 'ImageUrl');
    const bottomText = cmsUtils.payload(subItem, 'BottomText');
    const linkUrl = cmsUtils.payload(subItem, 'LinkUrl');
    return (
      <div key={index} css={style.sliderImageWrapper}>
        {linkUrl && linkUrl !== '' ? (
          <SiteLink to={linkUrl}>
            <div css={style.sliderImageWrapper2}>
              <img
                css={style.sliderImage}
                key={subItem.itemId}
                alt={'sliderimage-' + index}
                src={imageUrl}
              />
              <h5>{bottomText}</h5>
            </div>
          </SiteLink>
        ) : (
          <div css={style.sliderImageWrapper2}>
            <img
              css={style.sliderImage}
              key={subItem.itemId}
              alt={'sliderimage-' + index}
              src={imageUrl}
            />
            <h5>{bottomText}</h5>
          </div>
        )}
      </div>
    );
  });

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    fade: false,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      css={style.sliderAwardPanel}
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      <Slider {...settings}>{sliderAwardItems}</Slider>
    </div>
  );
}
