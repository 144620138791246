import { css } from '@emotion/core';
import { mq } from '../../cssInJs';

export default {
  top: css(
    mq({
      height: [44, null, 71],
      background: 'black',
      position: 'fixed',
      width: '100%',
      zIndex: '999',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'all 0.3s',
    })
  ),
  logo: css(mq({})),
  logoImg: css(
    mq({
      display: 'block',
      height: [75, null, 160],
      marginTop: ['3rem', null, '6rem'],
      transition: 'all .2s',
    })
  ),
  logoScrolled: css(
    mq({
      img: css(
        mq({
          height: [38, null, 65],
          marginTop: '0rem',
        })
      ),
    })
  ),
};
